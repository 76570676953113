@import "~@wpa/styles/scss/colours.scss";
@import "~@wpa/styles/scss/boxFrame.scss";
//@import "../_core/styles/fontawesome/fontawesome-pro-solid.scss";


html {
	background: $background-secondary url("/images/desktop-a4.jpg") no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	overflow-y:scroll;
}

.h-100 {
	min-height: 100vh;
}

.vh-center {
	display: flex;
	align-items: center; 
	justify-content: center;
}

.ant-layout-content {
	h1 {
		line-height: 1.1;
		small {
			display: block;
		}
	}
}

/*
 *	General typography
 */

 h1 {
	color: $color-light;
	font-weight: bold;
	margin-bottom: 1.5rem;
	font-size: 3.5rem;
}

h2 {
	color: $color-light;
}

ul.unstyled {
	margin-left: 0;
	padding-left: 0;
	list-style: none;
	li {
		margin-bottom: 0.5em;
	}
}
/**
 * @bugfix Prevent webkit from removing list semantics
 * https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
 * 1. Add a non-breaking space
 * 2. Make sure it doesn't mess up the DOM flow
 */
 ul.unstyled > li:before {
	content: "\200B"; /* 1 */
	position: absolute; /* 2 */
}


/*
 *	Buttons
 */

.ant-btn {
	/* Fix button wrapping in AntD */
	white-space: normal;
	height: auto;
	display: inline-flex;
	flex-wrap: wrap;
	svg + span {
		margin-left: 0.5em;
	}
	span {
		flex: 1 1 auto;
	}
}
.ant-btn-sm {
	.inlineSpinner {
		height: 1rem;
	}
}
.btn-color-picker {
	span {
		display:none;
	}
	vertical-align: middle;
	padding: 0 5px;
}


/*
 *	Forms
 */

.ant-upload.ant-upload-select-picture-card {
	width: 100%;
}


/*
 *	Menu
 */
 .ant-menu {
	margin-bottom: 1em;
	.ant-menu-item {
		height: auto;
		margin: 0;
		a {
			line-height: 2.5em;
		}
		// @todo: remove as this is BS only
		.nav-link {
			padding:0;
		}
	}
}

.ant-menu-dark {
	background-color: $background-secondary;
}

#appNav {
	margin-top: 1em;
	.ant-menu {
		// font-weight: bold;
		font-family: $headerFont;
		.ant-menu-item {
			margin: 0;
			a {
				line-height: 1em;
				padding: 0.5em 0;
				span {
					padding: 0 0 0 0.5rem;
				}
			}
		}
	}
	h1 {
		text-align: center;
	}
	// .menu {
	// 	margin: 1rem 0;
	// 	padding: 0;
	// 	list-style: none;
	// 	li {
	// 		margin: 0 0 0.5em 0;
	// 		padding: 0;
	// 		font-family: $headerFont;
	// 		a {
	// 			color: $color-light;
	// 			text-decoration: none;
	// 			span {
	// 				padding: 0 0 0 0.5rem;
	// 			}
	// 		}
	// 		a:hover {
	// 			color: $color-primary-2;
	// 		}
	// 	}
	// 	li:first-child {
	// 		margin-bottom: 1rem;
	// 	}
	// }
	.svg-inline--fa {
		vertical-align: 0;
	}
}


/*
 *	Alerts
 */
.ant-alert-error {
	color: #c00;
	.ant-alert-message {
		color: #c00;
	}
	.anticon-close {
		color: #c00;
	}
}



/*
 *	Cards
 */

.ant-card {
	background-clip: padding-box;
	margin-bottom: 1em;
	.ant-card-head {
		background-color: transparent;
		padding: 0.75rem;
		border-bottom: 0;
	}
	.ant-card-head-title {
		a:hover {
			text-decoration: none;
			border-bottom: 3px solid $color-primary-0;
		}
		padding:0;
		span {
			display: block;
			font-size: 0.5em;
			opacity: 0.75;
			line-height: 2em;
			font-family: Arial, serif;
		}
		span.anticon {
			font-size: 1em;
			vertical-align: baseline;
		}
	}
	.ant-card-extra {
		align-self: baseline;
		float: none;
		padding:0;
	}
	.ant-card-body {
		padding: 0.75rem;
	}
	.ant-card-head + .ant-card-body {
		padding-top: 0;
	}
	.color-secondary {
		background-color: rgba($color-secondary-1-0, 0.75);
	}
	.ant-card-actions {
		.ant-btn {
			height: auto;
		}
		// Drop the button style	
		.ant-btn-link {
			color: inherit;
		}
		.ant-btn-link:hover {
			color: inherit;
		}
	}
	.ant-alert-banner {
		margin: 0 -0.75rem 1rem -0.75rem;
	}
}
.ant-card-bordered {
	border: 0.5rem solid rgba(255, 255, 255, 0.5);
}


/*
 *		Quotes
 */

 blockquote.quote {

	&:before {
		content: '\201C';
		position: absolute;
		top: 0em;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		width: 3rem;
		height: 2rem;
		font: 6em/1.08em 'PT Sans', sans-serif;
		color: #666;
		text-align: center;
		border-radius: 1rem;
	}

	div {
		padding-top: 1em;
	}

	footer {
		text-align: right;

		cite:before {
			content: '— ';
		}
	}
}

/*
 *	Statuses
 */

 .status-idea {
	.title a {
		color: $color-status-idea;
	}
	.status,
	.btn-status {
		background: $color-status-idea;
	}
	.btn-status:focus {
		box-shadow: 0 0 0 0.2rem rgba($color-status-idea, 0.5);
	}
}

.status-outline {
	.title a {
		color: $color-status-outline;
	}
	.status,
	.btn-status {
		background: $color-status-outline;
	}
	.btn-status:focus {
		box-shadow: 0 0 0 0.2rem rgba($color-status-outline, 0.5);
	}
}

.status-draft {
	.title a {
		color: $color-status-draft;
	}
	.status,
	.btn-status {
		background: $color-status-draft;
	}
	.btn-status:focus {
		box-shadow: 0 0 0 0.2rem rgba($color-status-draft, 0.5);
	}
}

.status-done {
	.title a {
		color: $color-status-done;
	}
	.status,
	.btn-status {
		background: $color-status-done;
	}
	.btn-status:focus {
		box-shadow: 0 0 0 0.2rem rgba($color-status-done, 0.5);
	}
}


.ant-tag.status {
	font-family: $headerFont;
	letter-spacing: 1px;
	padding-bottom: 0.25em;
}


/*
 *		Modals
 */
.ant-modal-close svg {
	height: 56px;
}



/*
 *		Tree
 */
.ant-tree li .ant-tree-node-content-wrapper {
	height: auto !important;
}





/*
 *		Image selection
 */

.image-select-list {
	columns: 6 150px;
	column-gap: 0.75rem;
	div {
		button {
			background: none;
			border: 0;
			display: block;
			opacity: 0.85;
			padding: 0;
			/* Opacity quirk fix for hover size change */
			-webkit-transform: translateZ(0);
			&:hover {
				color: $color-primary-0;
				opacity: 1;
			}
			&:active, &:focus {
				color: $color-light;
				background-color: $color-primary-0;
				border:0;
				outline: 0.25rem solid $color-primary-0;
				opacity: 1;
			}
		}
		img {
			display: block;
			padding-bottom: 0.25rem;

		}
		font-size: 0.95em;
		margin-bottom: 0.5rem;
	}
	.selected {
		button {
			color: $color-light;
			background-color: $color-primary-0;
			border:0;
			outline: 0.25rem solid $color-primary-0;
			opacity: 1;
		}
	}
}














.modal-open {
	//  For some reason bootstrap insists on adding inline style for modal
	padding-right: 0px !important;
	//	Fix scrolling issue
	overflow: visible;
}

// .fade-enter {
// 	opacity: 0.01;
// }

// .fade-enter.fade-enter-active {
// 	opacity: 1;
// 	transition: opacity 1500ms ease-in;
// }

#appNavContainer {
	background: $background-secondary;
	overflow: auto;
	height: 100vh;
	position: fixed;
}
#appNavContainer + .ant-layout {
	margin-left: 200px;
}

#appMainContainer {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
#appMainContainer.assistant-open .secondaryMenu,
#appMainContainer.assistant-open .pageContent {
	padding-bottom: 18rem;
}



// .btn {
// 	font-family: $headerFont;
// 	.svg-inline--fa {
// 		vertical-align: 0;
// 	}
// }
// /*  Add gaps between multiple buttons */
// .btn + .btn {
// 	margin-left: 0.5rem;
// }

.alert {
	border-width: 5px;
}
.alert-dismissible .close {
	position: static;
	padding: 0;
}

.alert-icon {
	padding: 0;
	border-radius: 9px;

	button.close {
		order: 2;
		position: static;
		padding: 0.75rem;
	}
	.icon {
		width: 4rem;
		svg {
			height: 1.5rem;
			margin: 0 auto;
			padding-right: 5px;
		}
	}
	.content {
		padding: 1em;
		width: 100%;
	}

}
.alert-danger {
	fill: darken($danger, 10%);
	.icon {
		background-color: #f2bdb8;
	}
}
.alert-info {
	fill: darken($info, 10%);
	.icon {
	}
}
.alert-success {
	fill: darken($success, 10%);
	.icon {
	}
}
.alert-light {
	fill: darken($light, 10%);
	.icon {
	}
}



.tagList {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		margin: 0 0.5rem 0.5rem 0;
		.btn {
			color: $color-light;
			fill: $color-light;
			font-size: 0.7em;
			svg {
				width: 12px;
				font-size: 14px;
			}
		}
		.btn-inactive {
			opacity: 0.5;
		}
		.btn-inactive:hover {
			opacity: 0.75;
		}
	}
}

.tagListDark {
	li {
		.btn {
			color: $color-light;
			fill: $color-light;
			background: $color-primary-0;
		}
		.btn-inactive {
			opacity: 0.65;
			background: $color-light;
			color: $color-dark;
			fill: $color-dark;
		}
	}
}

.tagListText {
	li {
		.btn {
			font-size: 0.8em;
			color: $color-primary-0;
			fill: $color-primary-0;
			background: transparent;
			border-bottom: 2px solid transparent;
		}
		.btn:focus {
			box-shadow: none;
			border-bottom: 2px solid $color-primary-0;
		}
		.btn-inactive {
			opacity: 0.65;
			color: $color-dark;
			fill: $color-dark;
		}
		.btn-inactive:focus {
			border-bottom: 2px solid $color-dark;
		}
	}
}

.filter {
	h2 {
		color: $color-neutral;
		font-size: 1.5rem;
	}
	h3 {
		font-size: 1.2rem;
	}
	.statusList {
		li .btn svg {
			width: 12px;
			font-size: 14px;
		}
	}
	.authorList {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			margin-right: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}
	.author {
		display: block;
		height: 55px;
		overflow: hidden;
		margin: 0;
		background: $color-secondary-1-0;
		border: 5px solid #fff;
		img {
			width: 45px;
		}
		span {
			width: 45px;
			display: block;
			font-size: 11px;
			color: #000;
			line-height: 12px;
			text-align: center;
			padding-top: 5px;
		}
	}
	.author:hover {
		text-decoration: none;
		border-color: rgba(0, 0, 0, 0.15);
	}
	.author-active {
		border-color: rgba(0, 0, 0, 0.05);
	}
}

.card {
	background-clip: padding-box;
	margin-bottom: 1em;
	.card-header {
		background-color: transparent;
		padding-bottom: 0;
	}
	.card-header + .card-body {
		padding-top: 0;
	}
	.color-secondary {
		background-color: rgba($color-secondary-1-0, 0.75);
	}
}



.book.card {
	border-width: 0;
	font-size: 0.8rem;
	.card-header {
		border: 0;
		text-align: center;
		padding-bottom: 0.75rem;
	}
	.card-header + .card-body {
		padding-top: 0.75rem;
	}
	.card-body {
		border: 0;
	}
	.card-footer {
		background: $color-neutral;
		border: 0;
	}
	.author {
		display: block;
		float: left;
		clear: left;
		height: 45px;
		overflow: hidden;
		margin: 0 0 0 -0.75rem;
		img {
			width: 45px;
		}
		span {
			width: 45px;
			display: block;
			font-size: 11px;
			color: #000;
			line-height: 12px;
			text-align: center;
			padding-top: 5px;
		}
	}
	.title {
		padding-left: 45px;
		color: $color-neutral;
		line-height: 22px;
		font-size: 0.8rem;
		margin-bottom: 0;
		letter-spacing: 0.5px;
	}
	.title a:hover {
		text-decoration: none;
		//color: $color-primary-0;
	}
	.title-only {
		padding-left: 0;
	}
	.status {
		text-align: center;
		//background: $color-neutral;
		color: $color-light;
		text-transform: uppercase;
		//padding: 0.15rem 0.75rem;
	}
	.asset + .asset {
		margin-left: 0.5rem;
	}
}
.offered.card {
	background: #ddd;
	margin-bottom: 0;
	border: 0.25rem solid #fff;
}
.offered.card:after {
	content: '';
	position: absolute;
	left: 50%;
	//top: 50px;
	bottom: -0.5rem;
	width: 0;
	height: 0;
	margin-left: -0.25rem;
	border-left: 0.5rem solid transparent;
	border-right: 0.5rem solid transparent;
	border-top: 0.5rem solid #ddd;
	clear: both;
	z-index:999999;
}

ul.actions {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
	li {
		display: inline;
		padding: 0 0.25rem;
	}
	.btn-outline-primary {
		border: 0;
	}
	.btn-outline-primary:hover {
		background: none;
		color: #fff;
	}
}


.card-info {
	color: $color-light;
	background-color: rgba($color-secondary-1-2, .75);
	border:0;
}

.import {
	h2 {
		font-size: 1.5rem;
	}
	h3 {
		font-size: 1.2rem;
	}
	.bookList {
		list-style: none;
		padding:0;
		li {
			img {
				margin: 0.5rem 0;
			}
			label {
				display: block;
				font-size: 0.75rem;
				input {
					position: relative;
					margin: 0 0.25rem 0 0;
				}
			}
		}
	}
}

.wpa-dashboard {
	form input, form textarea {
		border: 0;
		border-left: 3px solid;
		border-color: $color-neutral-light;
		box-shadow: none !important;
		transition: border-color 0.15s, background-color 0.15s;
	}
	form input:focus, form textarea:focus {
		background-color: #f6f6f6;
	}
	form .input-group-text {
		border: 0;
		border-left: 3px solid;
		border-color: $color-light;
		box-shadow: none !important;
		background-color: $color-light;
	}
	form label,
	form legend,
	form .label {
		font-family: $headerFont;
	}
	form.grid-form .col-form-label {
		text-align: right;
	}
	form .is-valid {
		background: rgba($color-secondary-1-0, 0.05);
		//border-color: lighten($color-secondary-2-0, 25%);
		border-color: $color-neutral-light;
		//border-color: $color-primary-1;
	}
	form .is-invalid {
		background: lighten($danger, 45%);
		//border-color: $color-primary-1;
	}
}


.bookStatusLabel {
	font-family: $headerFont;
	font-size: 1.5rem;
}

#bookDetails {
	.author {
		display: block;
		float: left;
		clear: left;
		height: 45px;
		overflow: hidden;
		margin: 0 0 0 -0.75rem;
		img {
			width: 45px;
		}
		span {
			width: 45px;
			display: block;
			font-size: 11px;
			color: #000;
			line-height: 12px;
			text-align: center;
			padding-top: 5px;
		}
	}
}

.nav-pills {
	margin-bottom: 2rem;
	.nav-link {
		background: rgba(255,255,255,0.5);
		margin-right: 0.5rem;
	}
	.nav-link:hover {
		background: rgba(255,255,255,0.75);
	}
	.active:hover {
		background: $color-primary-3;
	}
}

.secondaryMenu {
	overflow: auto;
	height: 100vh;
	position: fixed;
	padding-top: 1em;
	.nav-link {
		margin-right: 0;
	}
	.ant-layout-sider-children {
		
	}
}
.secondaryMenu + .ant-layout {
	margin-left: 200px;
}

.nav-tabs {
	.nav-item {
		//border: 0.5rem solid transparent;
		//border-right: 0;
		//border-bottom: 0;
		//margin-bottom: -0.5rem;
		.nav-link {
			//background: $color-primary-0;
			//border-bottom: 0.5rem solid transparent;
		}
		.active {
			//background: #fff;
		}
	}
}
.tab-content {
	//border: 0.5rem solid ;
	.tab-pane {
		//background: #fff;//rgba(255,255,255,0.3);
	}
}


.react-tags {
	position: relative;
	padding: 0;
	border: 0;

	/* shared font styles */
	font-size: 1em;
	line-height: 1.2;

	/* clicking anywhere will focus the input */
	cursor: text;
}

.react-tags__selected {
	display: inline;
}

.react-tags__selected-tag {
	display: inline-block;
	box-sizing: border-box;
	margin: 0 6px 6px 0;
	padding: 6px 8px;
	border: 0;
	color: $color-light;
	background: $color-primary-0;

	/* match the font styles */
	font-size: inherit;
	line-height: inherit;
}

.react-tags__selected-tag:after {
	content: '\2715';
	color: $color-light;
	margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
	//border-color: lighten($color-primary-0, 25%);
	background-color: $color-primary-3;
}

.react-tags__search {
	display: inline-block;

	/* match tag layout */
	padding: 0;
	margin-bottom: 6px;

	/* prevent autoresize overflowing the container */
	max-width: 100%;
}

@media screen and (min-width: 30em) {

	.react-tags__search {
		/* this will become the offsetParent for suggestions */
		position: relative;
	}

}

.react-tags__search input {
	/* prevent autoresize overflowing the container */
	max-width: 100%;
	width: auto !important;

	/* remove styles and layout from this element */
	margin: 0;
	padding: 6px 8px;
	border: 0;
	outline: none;

	/* match the font styles */
	font-size: inherit;
	line-height: inherit;
	background-color: transparent;
}

.react-tags__search input::-ms-clear {
	display: none;
}

.react-tags__suggestions {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	z-index:99999
}

@media screen and (min-width: 30em) {
	.react-tags__suggestions {
		width: 240px;
	}

}

.react-tags__suggestions ul {
	margin: 4px -1px;
	padding: 0;
	list-style: none;
	background: $color-light;
	border: 0.5rem solid rgba($color-light, 0.5);
	//border-radius: 2px;
	//box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 6px 8px;
}

.react-tags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}

.react-tags__suggestions li:hover {
	cursor: pointer;
	background: #eee;
}

.react-tags__suggestions li.is-active {
	background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
	opacity: 0.5;
	cursor: auto;
}


.modal-dialog {
	margin-bottom: 15rem;
}

.modal .imageEdit {
	width: 90%;
	max-width: 1024px;
	.imagePreview {
		position: relative;
	}
	.imagePreview span {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
	}
}

.assistantBox {
	.assistant {
		.assistantName {
			font-family: $headerFont;
			font-weight: 500;
			font-size: 1.25rem;
			line-height: 1.2;

			display:inline-block;
			padding: 0.5rem 1rem 0.5rem 10rem;
			background: $color-primary-0;
			color: $color-light;
		}
		img {
			pointer-events: none;
			position:absolute;
			left: 0;
			bottom: 0;
		}
	}
	.assistantName:after {
		content: '';
		position: absolute;
		margin-left: 1rem;
		top: 0;
		width: 0;
		height: 0;
		border-bottom: 2.6rem solid $color-primary-0;
		border-right: 2.6rem solid transparent;
	}
	.content {
		background: $color-light;
		padding: 1rem 0 0 10rem;
		> div {
			max-height: 10rem;
			overflow-y: scroll;
		}
	}

	.bubble {
		display:block;
		margin: 1rem auto -1rem auto;
		width: 5rem;
		height: 5rem;
		background: #fff;
		padding: 0.75rem;
		border-radius: 3rem;
		box-shadow: inset 5px 5px 10px #eee;
		border: 5px solid #fff;
		img {
			width: 100%;
		}

		transition: box-shadow 0.75s ease;
	}
	.bubble:hover {
		box-shadow: inset 5px 5px 10px #eee, 0px 0px 15px $color-primary-0, 0px 0px 15px #fff;
	}

	form {
		float:left;
		clear:left;
		width:100%;
		max-width:650px;
		margin: 1rem 0 0 1rem;
	}
 }

.assistantFullBox {
	.ant-drawer-content {
		background: none;
	}
	
	.ant-drawer-content-wrapper {
		box-shadow: none !important;
	}
	.ant-drawer-body {
		padding: 0;
	}
}
.assistantMiniBox {
	text-align: center;
	position: absolute;
	bottom: 3rem;
	width: 100%;
	margin-left: -1em;
}


.assistant-pal {
	.bubble {
		position: relative;
		//span {
		//	background: url('/images/mascot-test-5b.png');
		//	display: block;
		//	position: absolute;
		//	width: 4.6rem;
		//	height: 7rem;
		//	background-size: 8rem;
		//	background-position-y: -4rem;
		//	background-position-x: center;
		//	bottom: 0rem;
		//	border-radius: 0 0rem 3rem 3rem;
		//	text-indent: -100rem;
		//	left: 0;
		//}
		span {
			background: url('/images/mascot-test-5b.png');
			display: block;
			position: absolute;
			bottom: 0;
			left:0;
			width: 4.5rem;
			height: 4.5rem;
			background-size: 7rem;
			background-position: center;
			text-indent: -100rem;
			border-radius: 2rem;
		}
	}
	img {
		-webkit-animation: assistantPal 2s linear infinite;
	}
}
.assistant-sarah {
	.bubble {

	}
	img {
		-webkit-animation: assistantSarah 1s linear infinite;
	}
}

@-webkit-keyframes assistantPal {
	0% { margin-bottom: 0; }
	50% { margin-bottom: 0.5rem; }
	100% { margin-bottom: 0; }
}
@-webkit-keyframes assistantSarah {
	0% { margin-bottom: -0.15rem; margin-left: 0; }
	50% { margin-bottom: 0; margin-left: 0rem; }
	100% { margin-bottom: -0.15rem; margin-left: 0; }
}

.speech-bubble {
	position: relative;
	background: $gray-200;
	border-radius: .4em;
	padding: 0.5rem 1rem;
	max-width: 650px;
	float:left;
	clear:left;
	margin-left: 1rem;
}
.speech-bubble+.speech-bubble {
	margin-top: 0.5rem;
}
.speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-right-color: $gray-200;
	border-left: 0;
	margin-top: -10px;
	margin-left: -10px;
}

.statusSetList {
	li {
		display: block;
		button {
			width: 100%;
			text-align: left;
		}
	}
}

.externalAssetsCard {
	.asset + .asset {
		margin-top: 1rem;
	}
	a {
		font-weight: bold;
	}
	a:hover {
		text-decoration: none;
	}
}

.input-group-append + .input-group-append {
	border-left: 3px solid $color-light;
}